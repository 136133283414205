/* .CollectionBlock {
  background-color: green;
  border: transparent;
  border-radius: 10px;
  min-width: 100px;
  overflow: hidden;
  transition: all 2s linear 3s;
}

.BottomBar {
  display: 'flex';
  z-index: 9999;
  flex-direction: 'row';
  align-items: 'center';
  justify-content: 'space-between';
  width: '100%';
  margin-top: '8px';
  padding: 4px 8px 4px 8px;
  border-radius: 0px 0px 6px 6px;
  opacity: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

.CollectionBlock:hover .BottomBar {
  transition: all 0.2s linear 0s;
	opacity: 1;
} */

.CollectionBlock {
  background-color: #F9F9F9;
  box-shadow: 0 2px 8px 0 rgba(0,0,0,0.05);

  overflow: hidden;
  transition: background-color 0.05s linear 0.05s;

}
.CollectionBlock:hover .CardContent {
  background-color: #EFEFEF;
}

.CollectionBlock:hover .CollectionButtons {
  display: flex;
  opacity: 1;
}

.CollectionBlock:hover .CollectionOptionsIcon {
  opacity: 1;
}

.CollectionButtons {
  display: flex;
  z-index: 9999;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: '100%';
  opacity: 0;
  transition: opacity 0.075s linear 0.075s;
}

.CollectionOptionsIcon {
  opacity: 0;
  box-shadow: 0 2px 8px 0 rgba(0,0,0,0.05);
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 9999;
  background-color: #EFEFEF;
  border-radius: 4px;
  border: 1px solid rgb(196, 196, 196, 0.45);
  transition: opacity 0.075s linear 0.075s;
}

.BottomBar {
  display: flex;
  z-index: 9999;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: '100%';
  margin-top: '8px';
  padding: 4px 8px 4px 8px;
  border-radius: 0px 0px 6px 6px;
  opacity: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}
.CollectionBlock:hover .BottomBar {
  transition: all 0.2s linear 0s;
	opacity: 1;
}
